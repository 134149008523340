<template>
  <div class="patient page">
    <NavMenu defaultActive="/patient/list">
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../../assets/img/icon_1.png" alt="ico-title" />
              <span>{{this.ruleForm.id ? $t('patient.editTitle') : $t('patient.create')}}</span>
            </div>
            <div class="mark">
              <span class="sig">*</span>
              <span>{{$t('patient.mark')}}</span>
            </div>
          </h2>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="form"
          >
            <div class="form-left">
              <el-form-item :label="$t('patient.patient')+':'" prop="patient_number"
                ><el-input v-model="ruleForm.patient_number"></el-input
              ></el-form-item>
              <el-form-item :label="$t('patient.name')+':'" prop="patient_name"
                ><el-input v-model="ruleForm.patient_name"></el-input
              ></el-form-item>
              <el-form-item :label="$t('patient.born')+':'" prop="birthday">
                <el-date-picker
                  type="date"
                  :placeholder="$t('patient.bornPlaceholder')"
                  v-model="ruleForm.birthday"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('patient.sex')+':'" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio class="radio" label="0">{{$t('patient.male')}}</el-radio>
                  <el-radio class="radio" label="1">{{$t('patient.female')}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-right">
              <el-form-item :label="$t('patient.remark')+':'">
                <el-input
                  :autosize="{ minRows: 6 }"
                  type="textarea"
                  maxlength="100"
                  show-word-limit
                  v-model="ruleForm.note"
                ></el-input>
              </el-form-item>
            </div>
            <div class="foot">
              <input
                type="button"
                :value="$t('common.save')"
                class="btn-submit"
                @click="submitForm('ruleForm')"
              />
              <input
                type="button"
                :value="$t('common.reset')"
                class="btn-cancel"
                @click="resetForm('ruleForm')"
              />
            </div>
          </el-form>
        </div>
      </div>
    </NavMenu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        patient_number: "",
        patient_name: "",
        birthday: "",
        sex: "",
        note: "",
		    id:""
      },
      rules: {
        patient_name: [
          { required: true, message: this.$t('patient.message1'), trigger: "blur" },
        ],
        patient_number: [
          { required: true, message: this.$t('patient.message2'), trigger: "blur" },
        ],

        sex: [{ required: true, message: this.$t('patient.message3'), trigger: "change" }],
        // note: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
    };
  },
  mounted(){
  	this.ruleForm.id = this.$route.query.id;
  	if(this.ruleForm.id) this.PatientDetail();
  },
  methods: {
	  PatientDetail(){
		  this.$api.patientDetail(this.ruleForm.id).then(res=>{
			  this.ruleForm.patient_number = res.data.patient_number
			  this.ruleForm.patient_name = res.data.patient_name
			  this.ruleForm.birthday = res.data.birthday_text
			  this.ruleForm.sex = res.data.sex
			  this.ruleForm.note = res.data.note
		  })
		  .catch(err => {
		  	// this.$message.error(this.$t('common.errMsg'));
		  })
	  },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.ruleForm.id){
          	this.$api.updatePatient(this.ruleForm).then(res=>{
          		
          		if(res.code == 1){
          			setTimeout(rso=>{
          				this.$router.go(-1)
          			},1000)
                return this.$message.success(res.msg);
          		}
              this.$message.error(res.msg);
          	})
          	.catch(err=>{
          		// this.$message.error(this.$t('common.errMsg'));
          	})
          }else{
          	this.$api.createPatient(this.ruleForm).then(res=>{
          		this.$message.success(res.msg);
          		if(res.code == 1){
          			setTimeout(rso=>{
          				this.$router.go(-1)
          			},1000)
                return this.$message.success(res.msg);
          		}
          	})
          	.catch(err=>{
          		// this.$message.error(this.$t('common.errMsg'));
          	})
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.right-content {
  position: relative;
}
.content-title .mark {
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  line-height: 159px;
  font-size: 13px;
}
.sig {
  color: #ff0000;
}
.right-content .form {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.right-content .form-left,
.right-content .form-right {
  width: 45%;
}
.right-content .form .foot {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
}
.right-content .form .foot input {
  margin: 0 12px;
}
</style>
